import React, { useMemo } from 'react';

import {
    setWidgetDevice,
    setWidgetLoader,
    setWidgetSource,
} from '@fiji/common/src/features/widgetManagement/widgetConfigurationSlice';

import {
    setMessageContent,
    setPageHeaderData,
    setAppHeaderSubTitle,
} from '@fiji/common/src/features/common/commonSlice';

import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useNavigationBack } from 'hooks';
import { setPredefinedGauge } from '@fiji/common/src/features/widgetManagement/gaugeWidgetSlice';
import { setPredefinedChart } from '@fiji/common/src/features/widgetManagement/chartWidgetSlice';
import { setPredefinedTrends } from '@fiji/common/src/features/widgetManagement/trendsWidgetSlice';

import { setPredefinedLoadTrends } from '@fiji/common/src/features/widgetManagement/loadTrendsWidgetSlice';
import { setPredefinedConsumptionBreakdown } from '@fiji/common/src/features/widgetManagement/consumptionBreakdownWidgetSlice';
import { setPredefinedMetrics } from '@fiji/common/src/features/widgetManagement/metricsWidgetSlice';
import { setPredefinedEnergyFlow } from '@fiji/common/src/features/widgetManagement/energyFlowWidgetSlice';
import { setPredefinedWeather } from '@fiji/common/src/features/widgetManagement/weatherWidgetSlice';
import { setDashboardWidgetIds } from '@fiji/common/src/features/dashboardManagement/dashboardSlice';
import { useLocation, useParams } from 'react-router-dom';
import { setPredefinedVirtualLoads } from '@fiji/common/src/features/widgetManagement/virtualLoadsWidgetSlice';
import { setPredefinedConfiguration } from '@fiji/common/src/features/widgetManagement/detailsWidgetSlice';
import {
    useGetDeviceByProfileQuery,
    useGetMultiDeviceLoadsQuery,
    useGetSearchedDeviceOrGroupQuery,
} from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useGetWidgetByIdQuery, useUpdateWidgetMutation } from '@fiji/common/src/features/widgetManagement/widgetApi';
import { useTranslation } from 'react-i18next';
import {
    setGroupListSelectedNodes,
    setPredefinedGroupListConfiguration,
} from '@fiji/common/src/features/widgetManagement/groupListWidgetSlice';
import {
    setMapSelectedNodes,
    setPredefinedMapConfiguration,
} from '@fiji/common/src/features/widgetManagement/mapWidgetSlice';
import {
    setDeviceListSelectedNodes,
    setPredefinedDeviceList,
} from '@fiji/common/src/features/widgetManagement/deviceListWidgetSlice';
import {
    setPredefinedTimelineConfiguration,
    setTimelineSelectedNodes,
} from '@fiji/common/src/features/widgetManagement/timelineWidgetSlice';
import { getWidgetTypeValidationConfig, isNullOrUndefined } from '@fiji/common/src/utils/helpers';
import { setPredefinedCommandBarConfiguration } from '@fiji/common/src/features/widgetManagement/commandBarWidgetSlice';
import { setPredefinedScheduleConfiguration } from '@fiji/common/src/features/widgetManagement/scheduleWidgetSlice';
import { setScheduleSelectedNodes } from '@fiji/common/src/features/schedule/scheduleCrudSlice';
import { setLoadsNodes, setPredefinedLoads } from '@fiji/common/src/features/widgetManagement/loadsWidgetSlice';
import { setPredefinedAggregatedTrends } from '@fiji/common/src/features/widgetManagement/aggregatedTrendsWidgetSlice';

type ReturnProps = {
    handleSaveButton: (args?: any) => Promise<void>;
    updateLoader: boolean;
    isLoading: boolean;
    deviceError: boolean;
    renderSx: any;
    details: { widgetDetails: any; deviceDetails: any };
    handleCloseButton: () => void;
    predefinedHandlers: (args: any) => any;
};

export const useWidgetConfigHandlers = (customSuccessMessage?: string): ReturnProps => {
    const { t } = useTranslation();
    const { widgetId } = useParams();
    const { state } = useLocation();
    const dispatch = useAppDispatch();
    const currentOrg = useTypedSelector(selectedOrg);
    const navigationBack = useNavigationBack();
    const widgetData = useTypedSelector((data: any) => data.widgetConfiguration.data);
    const selectedNode = useTypedSelector((data: any) => data?.widgetConfiguration?.device);
    const source = useTypedSelector((data: any) => data.widgetConfiguration.source);
    // const pageHeader = useTypedSelector((data: any) => data.common.pageHeader?.title);

    const saveAsCount = useTypedSelector((data: any) => data['widgetConfiguration']['saveAsCount']);
    const handleNavigateBack = (): void => {
        if (saveAsCount > 1) {
            navigationBack(saveAsCount);
        } else {
            navigationBack();
        }
    };
    const {
        data: widgetDetails,
        isLoading,
        isFetching,
        isError,
    }: any = useGetWidgetByIdQuery(widgetId, { skip: !widgetId || !currentOrg?.id });

    const {
        data: deviceDetails,
        isLoading: deviceDetailsLoading,
        isFetching: deviceDetailsFetching,
        isError: deviceError,
    } = useGetDeviceByProfileQuery(source?.id, {
        skip: !source?.id,
    });

    const [updateWidget, { isLoading: updateLoader }] = useUpdateWidgetMutation();

    const handleMultiLoadsPayload = (): any => {
        const payload: any = [];

        if (widgetDetails?.data?.config?.selectedNodes?.length) {
            widgetDetails?.data?.config?.selectedNodes?.forEach((item: any) => {
                const selectedIndex = payload?.findIndex((subItem: any) => subItem.deviceId === item.deviceId);
                const selectedItem = payload?.find((subItem: any) => subItem.deviceId === item.deviceId);
                if (selectedItem) {
                    payload?.[selectedIndex]?.loadId?.push(item.loadId);
                } else {
                    payload?.push({ deviceId: item.deviceId, loadId: [item.loadId] });
                }
            });
        }
        return payload;
    };

    const { data: searchedData } = useGetSearchedDeviceOrGroupQuery(
        {
            entityIds: widgetDetails?.data?.config?.filters
                ? Object?.values(widgetDetails?.data?.config?.filters)?.flat(Infinity)
                : [],
        },
        {
            skip:
                !widgetDetails?.data?.config ||
                !widgetDetails?.data?.config?.filters ||
                !Object?.keys(widgetDetails?.data?.config?.filters)?.length,
        }
    );

    const { data: loads } = useGetMultiDeviceLoadsQuery(handleMultiLoadsPayload(), {
        skip: !widgetDetails?.data?.config?.selectedNodes?.length || widgetDetails?.data?.widgetType?.id !== 'loads',
    });

    React.useEffect(() => {
        if (widgetDetails && widgetDetails?.data?.widgetType?.id === 'loads' && loads?.data?.response?.length) {
            const newNodesArr: any = [];
            loads?.data?.response?.forEach((deviceConfig: any) => {
                deviceConfig?.configurationList?.forEach((load: any) => {
                    newNodesArr.push({
                        loadId: load?.loadId,
                        id: `${load?.loadId}${deviceConfig?.deviceId}`?.replace(/-/g, '_'),
                        name: load?.loadName,
                        type: 'load',
                        groupPath: deviceConfig?.groupPath,
                        deviceId: deviceConfig?.deviceId,
                        icon: {
                            web: {
                                family: 'loads',
                                loadNumber: load?.loadNumber,
                                sx: {
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    backgroundColor: '#727E84',
                                    color: '#fff',
                                    width: '18px',
                                    height: '18px',
                                    borderRadius: '4px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                },
                            },
                        },
                    });
                });
            });
            dispatch(setLoadsNodes({ key: 'selectedNodes', value: newNodesArr }));
        }
    }, [loads, widgetDetails]);

    React.useEffect(() => {
        if (widgetData && searchedData?.data?.records && !widgetData?.selectedNodes) {
            switch (widgetDetails?.data?.widgetType?.id) {
                case 'group_list':
                    dispatch(setGroupListSelectedNodes({ key: 'selectedNodes', value: searchedData?.data?.records }));
                    break;

                case 'device_list':
                    dispatch(setDeviceListSelectedNodes({ key: 'selectedNodes', value: searchedData?.data?.records }));
                    break;
                case 'map':
                    dispatch(setMapSelectedNodes({ key: 'selectedNodes', value: searchedData?.data?.records }));
                    break;

                case 'timeline':
                    dispatch(setTimelineSelectedNodes({ key: 'selectedNodes', value: searchedData?.data?.records }));
                    break;

                case 'schedule':
                    dispatch(setScheduleSelectedNodes({ key: 'selectedNodes', value: searchedData?.data?.records }));
                    break;
                default:
                    break;
            }
        }
    }, [searchedData, widgetData]);

    React.useEffect(() => {
        if (widgetDetails?.data?.sourceId && !widgetDetails?.data?.multiDevice && !widgetData?.multiDevice && !source) {
            dispatch(
                setWidgetSource({
                    id: widgetDetails?.data?.sourceId,
                    name: widgetDetails?.data?.sourceName,
                    categoryId: widgetDetails?.data?.categoryId,
                })
            );
        }
    }, [widgetDetails, widgetData?.multiDevice, source]);

    React.useEffect(() => {
        if (widgetDetails) {
            dispatch(setPageHeaderData({ title: widgetDetails?.data?.name }));
            dispatch(setAppHeaderSubTitle(t('WIDGETS:WIDGETS_BUILDER')));
        }
    }, [widgetDetails]);

    // React.useEffect(() => {
    //     if (state.assignId) {
    //         dispatch(setWidgetDevice({ data: { id: state.assignId }, reset: false }));
    //     }
    // }, [state.assignId]);

    React.useEffect(() => {
        if (deviceDetails && deviceDetails?.data && !selectedNode?.id) {
            dispatch(setWidgetDevice({ data: deviceDetails?.data, reset: false }));
        }
    }, [deviceDetails]);

    React.useEffect(() => {
        if (isError) {
            dispatch(setWidgetLoader(false));
        }
    }, [isError]);

    async function handleSaveButton(cancelCallback?: any): Promise<void> {
        const configClone: any = JSON.parse(JSON.stringify(widgetData));
        switch (widgetDetails?.data?.widgetType?.id) {
            case 'timeline':
            case 'device_list':
            case 'group_list':
                if (!configClone['multiDevice']) {
                    delete configClone['filters'];
                }
                if (Array.isArray(configClone?.preferences) && !configClone?.preferences?.length) {
                    delete configClone['preferences'];
                }
                delete configClone['selectedNodes'];
                delete configClone['secondary'];
                delete configClone['statusCount'];
                break;
            case 'map':
                if (!configClone['multiDevice']) {
                    delete configClone['filters'];
                }
                delete configClone['selectedNodes'];
                delete configClone['markers'];
                break;

            case 'gauge':
                delete configClone['data']['value'];
                break;
            case 'properties':
            case 'settings':
            case 'details':
                if (configClone['primary']) {
                    configClone['primary']?.forEach((item: any) => {
                        item?.channels?.forEach((subItem: any) => {
                            delete subItem?.value;
                        });
                    });
                }
                if (configClone['secondary']) {
                    configClone['secondary']?.forEach((item: any) => {
                        item?.channels?.forEach((subItem: any) => {
                            delete subItem?.value;
                        });
                    });
                }
                break;
            case 'charts':
                configClone['secondary']?.forEach((item: any) => {
                    delete item.value;
                });
                break;
            case 'trends':
                configClone['secondary']?.forEach((item: any) => {
                    delete item?.data;
                });
                if (configClone['trendsLegendData']) delete configClone['trendsLegendData'];
                break;
            case 'loads':
                if (!configClone['multiDevice']) {
                    delete configClone['selectedNodes'];
                    configClone['secondary'] = configClone['secondary']?.map((item: any) => item.loadId);
                } else {
                    configClone['selectedNodes'] = configClone['selectedNodes']?.map((item: any) => ({
                        loadId: item.loadId,
                        deviceId: item.deviceId,
                    }));
                    delete configClone['secondary'];
                }
                break;
            case 'energy_flow':
                configClone['assets'] = configClone['assets']?.map((item: any) => ({
                    id: item.id,
                    deviceTypeId: item.deviceTypeId,
                    name: item.name,
                }));
                break;
            case 'metrics':
            case 'consumption_breakdown':
            case 'load_trends':
                configClone['assets'] = configClone['assets']?.map((item: any) => ({
                    id: item?.id,
                    deviceTypeId: item?.deviceTypeId,
                    name: item?.name,
                }));
                break;
            case 'weather':
                configClone['assets'] = {
                    parentId: configClone['assets']?.parentId,
                    id: configClone['assets']?.id,
                    name: configClone['assets']?.name,
                    lat: configClone['assets']?.lat,
                    lng: configClone['assets']?.lng,
                };
                break;
            default:
                break;
        }

        if (
            widgetDetails?.data?.widgetType?.id === 'timeline' ||
            widgetDetails?.data?.widgetType?.id === 'device_list'
        ) {
            delete configClone['secondary'];
            delete configClone['statusCount'];
        } else if (widgetDetails?.data?.widgetType?.id === 'gauge') {
            delete configClone['data']['value'];
        }

        const { error }: any = await updateWidget({
            body: {
                config: configClone,
                sourceId: source ? source?.id : null,
                sourceName: source ? source?.name : null,
            },
            widgetId: widgetId,
        });
        if (!error) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: customSuccessMessage ?? `${widgetDetails?.data?.name ?? '-'} has been saved.`,
                })
            );
            if (!isNullOrUndefined(cancelCallback)) {
                cancelCallback();
            }
        }
    }

    function predefinedHandlers(data: any): void {
        switch (widgetDetails?.data?.widgetType?.id) {
            case 'details':
            case 'settings':
            case 'properties':
                dispatch(setPredefinedConfiguration(data));
                break;
            case 'gauge':
                dispatch(setPredefinedGauge(data));
                break;
            case 'charts':
                dispatch(setPredefinedChart(data));
                break;
            case 'trends':
                dispatch(setPredefinedTrends(data));
                break;
            case 'virtual_loads':
                dispatch(setPredefinedVirtualLoads(data));
                break;
            case 'group_list':
                dispatch(setPredefinedGroupListConfiguration(data));
                break;
            case 'map':
                dispatch(setPredefinedMapConfiguration(data));
                break;
            case 'device_list':
                dispatch(setPredefinedDeviceList(data));
                break;
            case 'timeline':
                dispatch(setPredefinedTimelineConfiguration(data));
                break;
            case 'command_bar':
                dispatch(setPredefinedCommandBarConfiguration(data));
                break;
            case 'schedule':
                dispatch(setPredefinedScheduleConfiguration(data));
                break;
            case 'loads':
                dispatch(setPredefinedLoads(data));
                break;
            case 'load_trends':
                dispatch(setPredefinedLoadTrends(data));
                break;
            case 'consumption_breakdown':
                dispatch(setPredefinedConsumptionBreakdown(data));
                break;
            case 'metrics':
                dispatch(setPredefinedMetrics(data));
                break;
            case 'energy_flow':
                dispatch(setPredefinedEnergyFlow(data));
                break;
            case 'weather':
                dispatch(setPredefinedWeather(data));
                break;
            case 'aggregated_trends':
                dispatch(setPredefinedAggregatedTrends(data));
                break;
            default:
                break;
        }
    }

    function redirectionHandler(): void {
        if (state?.assignType) {
            dispatch(setDashboardWidgetIds(widgetDetails?.data?.id));
        }
        handleNavigateBack();
    }

    const renderSx = (): any => {
        if (getWidgetTypeValidationConfig(state?.typeId)['loaderType'] === 'progress') {
            return {
                position: 'relative',
                minHeight: '400px',
            };
        }
        return { position: 'relative' };
    };

    return {
        handleSaveButton,
        updateLoader,
        renderSx,
        deviceError,
        isLoading: useMemo(
            () => isLoading || isFetching || deviceDetailsFetching || deviceDetailsLoading,
            [isLoading || isFetching || deviceDetailsFetching || deviceDetailsLoading]
        ),
        details: useMemo(
            (): any => ({ widgetDetails: widgetDetails?.data, deviceDetails: deviceDetails?.data }),
            [widgetDetails?.data, deviceDetails?.data]
        ),
        handleCloseButton: redirectionHandler,
        predefinedHandlers,
    };
};
