import React from 'react';
import { CustomPlaceholder } from './CustomPlaceHolder';
import { IconButton, InputBase, Stack } from '@mui/material';
import { Search } from '@mui/icons-material';

export const InputWithCustomPlaceholder = ({ placeholder, ...props }: any): JSX.Element => {
    const [hasValue, setHasValue] = React.useState(false);
    React.useEffect(() => {
        if (props?.value) {
            setHasValue(true);
        }
    }, [props?.value]);

    return (
        <Stack className={`${props?.noBorders ? 'w-100' : 'border-1 border-radius-4'}`} direction={'row'}>
            {props?.noGlobalSearch ? (
                <></>
            ) : (
                <IconButton type="button" className="padding-10 color-content f-16 " aria-label="search">
                    <Search />
                </IconButton>
            )}
            <Stack className="w-100" sx={{ height: '40px', position: 'relative', bottom: '5px' }}>
                <CustomPlaceholder hasValue={hasValue} placeholder={placeholder} />
                <InputBase
                    {...props}
                    onBlur={(e) => setHasValue(e.target.value.length > 0)}
                    onFocus={() => setHasValue(true)}
                    className={`f-16 fw-600 w-100 padding-x-8 ${hasValue ? 'padding-y-12' : ''}`}
                />
            </Stack>
        </Stack>
    );
};
