import React, { useState, useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import CustomIcon from 'components/CustomIcon';
import { getTimeZoneOffset } from 'utils/helpers';

const getInterpolatedIndex = (timestamp: number, timeArray: number[], offset: number): number => {
    const targetTime = new Date(timestamp).getTime() - offset;
    for (let i = 0; i < timeArray?.length - 1; i++) {
        const current = timeArray[i];
        const next = timeArray[i + 1];
        if (targetTime >= current && targetTime <= next) {
            const ratio = (targetTime - current) / (next - current);
            return i + ratio;
        }
    }
    return -1; // Return -1 if the timestamp is out of bounds
};

const getIconDetails = (condition: string): any => {
    switch (condition) {
        case 'cloudy':
            return {
                web: {
                    name: 'Cloud',
                    family: 'material-ui',
                },
                mobile: {
                    name: 'Cloud',
                    family: 'material-ui',
                },
                additionalProps: {
                    style: { color: '#D5D8DA' },
                },
            };
        case 'rain':
        case 'drizzle':
            return {
                web: {
                    name: 'Grain',
                    family: 'material-ui',
                },
                mobile: {
                    name: 'Grain',
                    family: 'material-ui',
                },
                additionalProps: {
                    style: { color: '#7ABADE' },
                },
            };
        case 'thunder':
        case 'snow_thunder':
        case 'rain_thunder':
            return {
                web: {
                    name: 'Thunderstorm',
                    family: 'material-ui',
                },
                mobile: {
                    name: 'Thunderstorm',
                    family: 'material-ui',
                },
                additionalProps: {
                    style: { color: '#D5D8DA' },
                },
            };
        case 'snow':
        case 'ice_pallets':
        case 'sleet':
            return {
                web: {
                    name: 'AcUnit',
                    family: 'material-ui',
                },
                mobile: {
                    name: 'AcUnit',
                    family: 'material-ui',
                },
                additionalProps: {
                    style: { color: '#7ABADE' },
                },
            };
        case 'fog':
            return {
                web: {
                    name: 'BlurOn',
                    family: 'material-ui',
                },
                mobile: {
                    name: 'BlurOn',
                    family: 'material-ui',
                },
                additionalProps: {
                    style: { color: '#D5D8DA' },
                },
            };
        case 'sunny':
        case 'clear':
        default:
            return {
                web: {
                    name: 'WbSunny',
                    family: 'material-ui',
                },
                mobile: {
                    name: 'WbSunny',
                    family: 'material-ui',
                },
                additionalProps: {
                    style: { color: '#F0AA1F' },
                },
            };
    }
};

const dayNightIcons = {
    day: {
        web: {
            name: 'WbSunny',
            family: 'material-ui',
        },
        mobile: {
            name: 'WbSunny',
            family: 'material-ui',
        },
        additionalProps: {
            style: {
                color: '#A59116',
            },
        },
    },
    night: {
        web: {
            name: 'NightlightRound',
            family: 'material-ui',
        },
        mobile: {
            name: 'NightlightRound',
            family: 'material-ui',
        },
        additionalProps: {
            style: {
                color: '#1C77CD',
            },
        },
    },
};

const renderIconToString = (iconName: string, family: string, style: any): string => {
    const iconElement = <CustomIcon iconName={iconName} family={family} iconProps={style} />;
    return renderToStaticMarkup(iconElement);
};

const getLabelFormat = (selectedTimeFormat: any): string => {
    switch (selectedTimeFormat) {
        case 'today':
            return '%H:%M';
        case 'week':
            return '%A';
        default:
            return '%H:%M';
    }
};

const getTooltipFormatter = function (this: any, props: any): string {
    const xValue = typeof this.x === 'number' ? Highcharts.dateFormat('%A, %b %e, %H:%M', this.x) : '';
    let tooltipText = `<span style="font-size: 10px">${xValue}</span><br/>`;
    if (this.points) {
        this.points.forEach((point: any) => {
            let suffix = '';
            if (point.series.name === 'Max temperature' || point.series.name === 'Min Temperature') {
                suffix = props?.temperaturePreference === 'F°(Fahrenheit)' ? ' °F' : ' °C';
            } else if (point.series.name === 'Rainfall') {
                suffix = ' mm';
            }
            tooltipText += `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${point.y} ${suffix}</b><br/>`;
        });
    }
    return tooltipText;
};

const getPositioner = function (this: any, labelWidth: any, labelHeight: any, point: any): any {
    let x = point.plotX + this.chart.plotLeft + 10; // Position to the right
    const y = point.plotY + this.chart.plotTop - labelHeight / 2;

    // Ensure tooltip stays within the chart container
    if (x + labelWidth > this.chart.chartWidth) {
        x = this.chart.chartWidth - labelWidth - 10; // Position to the left if it overflows
    }
    return { x: x, y: y };
};

export const WeatherWidget = (props: any): JSX.Element => {
    const [options, setOptions] = useState<Highcharts.Options>({});
    const chartData = props?.data;
    const timezoneOffsetMs = getTimeZoneOffset(chartData?.timeZone);
    const xAxisData = chartData?.forecast?.map((el: any) => el?.timestamp - timezoneOffsetMs);
    const minTempData = chartData?.forecast?.map((el: any) => el?.min_temp);
    const maxTempData = chartData?.forecast?.map((el: any) => el?.max_temp);
    const rainfallData = chartData?.forecast?.map((el: any) => el?.rain);
    const iconData = chartData?.forecast?.map((el: any) => getIconDetails(el?.condition));

    useEffect(() => {
        setOptions({
            chart: {
                zooming: {
                    type: 'xy',
                },
            },
            title: {
                text: undefined, // This removes the chart title
            },
            ...(props.mode === 'viewAll' && {
                legend: {
                    align: 'right',
                    verticalAlign: 'top',
                },
            }),
            xAxis: [
                {
                    categories: xAxisData,
                    lineWidth: 1,
                    labels: {
                        formatter: ({ value, pos }): string => {
                            const timestamp = Number(value);
                            const formattedValue = Highcharts.dateFormat(
                                getLabelFormat(props?.selectedTimePeriod),
                                timestamp
                            );
                            if (pos === 0 && props?.selectedTimePeriod === 'week') {
                                return `<span style="color: #007bc1;">${formattedValue}</span>`;
                            }
                            return formattedValue;
                        },
                    },
                    tickInterval: props?.selectedTimePeriod === 'today' ? 4 : 1,
                    plotBands:
                        props?.selectedTimePeriod === 'today'
                            ? [
                                  {
                                      from: -1,
                                      to: getInterpolatedIndex(chartData?.sunrise, xAxisData, timezoneOffsetMs),
                                      color: 'rgba(244, 249, 251, 1)',
                                      label: {
                                          useHTML: true,
                                          text: renderIconToString(
                                              dayNightIcons.night.web.name,
                                              dayNightIcons.night.web.family,
                                              dayNightIcons.night.additionalProps
                                          ),
                                          align: 'left', // Align text to left
                                          x: 10,
                                          y: 15,
                                      },
                                  },
                                  {
                                      from: getInterpolatedIndex(chartData?.sunrise, xAxisData, timezoneOffsetMs),
                                      to: getInterpolatedIndex(chartData?.sunset, xAxisData, timezoneOffsetMs),
                                      color: 'rgba(255, 251, 239, 1)',
                                      label: {
                                          useHTML: true,
                                          text: renderIconToString(
                                              dayNightIcons.day.web.name,
                                              dayNightIcons.day.web.family,
                                              dayNightIcons.day.additionalProps
                                          ),
                                          align: 'left', // Align text to left
                                          x: 10,
                                          y: 15,
                                      },
                                  },
                                  {
                                      from: getInterpolatedIndex(chartData?.sunset, xAxisData, timezoneOffsetMs),
                                      to: xAxisData?.length - 1,
                                      color: 'rgba(244, 249, 251, 1)',
                                      label: {
                                          useHTML: true,
                                          text: renderIconToString(
                                              dayNightIcons.night.web.name,
                                              dayNightIcons.night.web.family,
                                              dayNightIcons.night.additionalProps
                                          ),
                                          align: 'left', // Align text to left
                                          x: 10,
                                          y: 15,
                                      },
                                  },
                              ]
                            : [],
                },
                {
                    lineWidth: 0,
                    opposite: true,
                    linkedTo: 0,
                    tickInterval: props?.selectedTimePeriod === 'today' ? 4 : 1,
                    tickLength: 0,
                    labels: {
                        enabled: true,
                        useHTML: true,
                        align: 'center',
                        y: -5,
                        formatter: ({ pos }): string => {
                            if (pos >= 0 && pos < iconData?.length) {
                                const iconInfo = iconData[pos]?.web;
                                const iconStyle = iconData[pos]?.additionalProps;
                                if (iconInfo) {
                                    return renderIconToString(iconInfo?.name, iconInfo?.family, iconStyle);
                                }
                            }
                            return '';
                        },
                    },
                },
            ],
            yAxis: [
                {
                    min: 0,
                    title: {
                        text: 'Temperature',
                    },
                    labels: {
                        format: props?.temperaturePreference === 'F°(Fahrenheit)' ? '{value}°F' : '{value}°C',
                        overflow: 'justify',
                    },
                    lineWidth: 1,
                    maxPadding: 0.5,
                },

                {
                    gridLineWidth: 0,
                    title: {
                        text: 'Rainfall',
                    },
                    labels: {
                        format: '{value}mm/h',
                    },
                    opposite: true,
                    maxPadding: 0.5,
                },
            ],
            tooltip: {
                shared: true,
                formatter: function () {
                    return getTooltipFormatter.call(this, props);
                },
                positioner: function (labelWidth, labelHeight, point) {
                    return getPositioner.call(this, labelWidth, labelHeight, point);
                },
                outside: true,
            },
            series: [
                {
                    name: 'Max temperature',
                    showInLegend: true,
                    type: 'line',
                    zIndex: 1,
                    color: '#F47721',
                    yAxis: 0,
                    data: maxTempData,
                },
                {
                    name: 'Min Temperature',
                    type: 'line',
                    color: '#004B9E',
                    zIndex: 1,
                    yAxis: 0,
                    data: minTempData,
                },
                {
                    name: 'Rainfall',
                    type: 'column',
                    color: '#0088F2',
                    yAxis: 1,
                    data: rainfallData,
                },
            ],
            credits: {
                enabled: false,
            },
        });
    }, [chartData]);

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};
