import { api } from '../../app/api/baseApi';
import { PAGINATION_PAYLOAD } from '../../constants/paginationQuery';

export const realmApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllRealms: builder.mutation<any, any>({
            query: ({ parentName, paginationPayload, headers, type }: any) => ({
                url: `rm/v1/realms/filter`,
                method: 'POST',
                body: paginationPayload ?? {
                    size: 1000,
                    page: 0,
                },
                params: {
                    parent: parentName,
                    ...(type && {
                        type,
                    }),
                },
                ...(headers && { headers }),
            }),
        }),
        getAllRealmsForNonAdminRoles: builder.mutation<any, any>({
            query: ({ parentName, paginationPayload, headers }: any) => ({
                url: `rm/v1/realms/hierarchy`,
                method: 'POST',
                body: paginationPayload ?? {
                    size: 1000,
                    page: 0,
                },
                params: {
                    parent: parentName,
                },
                ...(headers && { headers }),
            }),
        }),
        getRealmById: builder.query<object, string>({
            query: (realmPrefix: string) => ({
                url: `rm/v1/realms/${realmPrefix}`,
            }),
            providesTags: ['RealmById'],
        }),
        createRealm: builder.mutation<object, object>({
            query: (body) => ({
                url: `rm/v1/realms`,
                method: 'POST',
                body: body,
            }),
            // invalidatesTags: (result, error): any => (!error ? ['PaginatedRealms'] : []),
        }),
        updateRealmDetails: builder.mutation<object, any>({
            query: ({ body, realmPrefix }: any) => ({
                url: `rm/v1/realms/${realmPrefix}`,
                method: 'PATCH',
                body: body,
            }),
            invalidatesTags: (result, error, args): any => {
                if (!error && (args?.realmPrefix === args?.body?.name || !args?.body?.name)) {
                    return ['RealmById'];
                }
                return [];
            },
        }),
        updateLogo: builder.mutation<object, any>({
            query: ({ body, params, realmPrefix }: any) => ({
                url: `rm/v1/realms/${realmPrefix}/images`,
                method: 'PUT',
                body: body,
                params: params,
            }),
            invalidatesTags: (result, error): any => (!error ? ['RealmSettings'] : []),
        }),
        deleteLogo: builder.mutation<object, any>({
            query: ({ realmPrefix, params }: any) => ({
                url: `rm/v1/realms/${realmPrefix}/images`,
                method: 'DELETE',
                params: params,
            }),
            invalidatesTags: (result, error): any => (!error ? ['RealmSettings'] : []),
        }),
        deleteRealm: builder.mutation<object, string>({
            query: (prefix: string) => ({
                url: `rm/v1/realms/${prefix}`,
                method: 'DELETE',
            }),
        }),
        getRealmPermissions: builder.query<any, any>({
            query: (params) => ({
                url: 'rm/v1/realms/permissions',
                params: params,
            }),
        }),
        validateRealm: builder.query<object, any>({
            query: (params) => ({
                url: 'rm/v1/realms/check-availability',
                params: params,
            }),
        }),
        getRealmSettings: builder.query<object, any>({
            query: (realmPrefix) => ({
                url: `rm/v1/realms/${realmPrefix}/settings`,
            }),
            providesTags: ['RealmSettings'],
        }),
        getOpenRealmSettings: builder.query<object, any>({
            query: (realmPrefix) => ({
                url: `rm/v1/open/realms/settings`,
                params: { realmName: realmPrefix },
            }),
            extraOptions: 'no-toast',
        }),
        updateRealmSettings: builder.mutation<object, any>({
            query: ({ body, realmPrefix }: any) => ({
                url: `rm/v1/realms/${realmPrefix}/settings`,
                method: 'PATCH',
                body: body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['RealmSettings'] : []),
        }),
        moveRealmAndOrgs: builder.mutation<object, any>({
            query: (body: any) => ({
                url: `rm/v1/realms/move/hierarchy`,
                method: 'PATCH',
                body: body,
            }),
        }),
        suspendRestoreRealm: builder.mutation<object, any>({
            query: ({ body, realmPrefix }: any) => ({
                url: `rm/v1/realms/${realmPrefix}/suspend-restore`,
                method: 'PATCH',
                params: body,
            }),
            invalidatesTags: (result, error, args): any => {
                if (!error && (args?.realmPrefix === args?.body?.name || !args?.body?.name)) {
                    return ['RealmById'];
                }
                return [];
            },
        }),
        updateCustomUrl: builder.mutation<object, any>({
            query: ({ body, realmPrefix }: any) => ({
                url: `rm/v1/realms/${realmPrefix}/custom-url`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['RealmById'] : []),
        }),
        downloadAndView: builder.mutation<object, any>({
            query: ({ realmPrefix, params }: any) => ({
                url: `rm/v1/realms/${realmPrefix}/certificate`,
                method: 'GET',
                params,
            }),
        }),
        enabledDisableCustomUrl: builder.mutation<object, any>({
            query: ({ realmPrefix, params }: any) => ({
                url: `rm/v1/realms/${realmPrefix}/custom-url`,
                method: 'PATCH',
                params,
            }),
            invalidatesTags: (result, error): any => (!error ? ['RealmById'] : []),
        }),
        deleteCustomUrl: builder.mutation<object, any>({
            query: ({ realmPrefix }: any) => ({
                url: `rm/v1/realms/${realmPrefix}/custom-url`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error): any => (!error ? ['RealmById'] : []),
        }),
        getPaginatedRealms: builder.query({
            query: (params: any) => {
                const payloadClone = JSON.parse(JSON.stringify(params));
                if (payloadClone.pullRefresh) {
                    delete payloadClone.pullRefresh;
                }
                return {
                    url: 'rm/v1/realms',
                    method: 'GET',
                    params: payloadClone,
                };
            },
            ...PAGINATION_PAYLOAD,
            // providesTags: ['PaginatedRealms'],
        }),
    }),
});

export const {
    useGetAllRealmsMutation,
    useGetAllRealmsForNonAdminRolesMutation,
    useValidateRealmQuery,
    useGetRealmSettingsQuery,
    useCreateRealmMutation,
    useGetRealmPermissionsQuery,
    useUpdateRealmSettingsMutation,
    useUpdateRealmDetailsMutation,
    useGetRealmByIdQuery,
    useDeleteRealmMutation,
    useUpdateLogoMutation,
    useDeleteLogoMutation,
    useGetOpenRealmSettingsQuery,
    useMoveRealmAndOrgsMutation,
    useSuspendRestoreRealmMutation,
    useUpdateCustomUrlMutation,
    useDownloadAndViewMutation,
    useEnabledDisableCustomUrlMutation,
    useDeleteCustomUrlMutation,
    useGetPaginatedRealmsQuery,
} = realmApi;
