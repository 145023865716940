import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import ChartTooltip from './ChartTooltip';
import ReactDOMServer, { renderToStaticMarkup } from 'react-dom/server';
import { SeriesData } from './data';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getTimeZoneOffset } from 'utils/helpers';
import { systemTimezone } from '@fiji/common/src/utils/helpers';
import CustomIcon from 'components/CustomIcon';
dayjs.extend(utc);

HighchartsMore(Highcharts);

type DataItem = {
    label: string;
    consumption: number | null;
    supply: number | null;
    color: string;
};

function getMonthFromTimestamp(timestamp: number, timeZone: any): string {
    // Convert milliseconds to seconds for dayjs.unix()
    const date = dayjs.utc(timestamp).tz(timeZone);
    return date.format('MMMM');
}

function convertTimestampToRangeFormat(
    timestampRange: string,
    timeZone: any,
    format = 'MM/DD/YYYY'
): string | undefined {
    if (typeof timestampRange !== 'string') {
        return;
    }
    const [startTimestamp, endTimestamp] = timestampRange.split('-').map(Number);
    const formatDate = (timestamp: number): any => dayjs.utc(timestamp).tz(timeZone).format(format);
    const startDate = formatDate(startTimestamp);
    const endDate = formatDate(endTimestamp);

    return `${startDate}-${endDate}`;
}

const getSeriesData = (timePeriod: string, el: any[], dateFormat: string, timeZone: any): any[] => {
    switch (timePeriod) {
        case 'week':
            return [getDayOfWeek(el[0]), ...el.slice(1)];
        case 'month':
            return [convertTimestampToRangeFormat(el[0], timeZone, dateFormat), ...el.slice(1)];
        case 'year':
            return [getMonthFromTimestamp(el[0], timeZone), ...el.slice(1)];
        default:
            return el;
    }
};

function tooltipFormatter(
    this: any,
    data: SeriesData[],
    categoryArr: any,
    unit: string,
    selectedTimePeriod: string,
    timeZone: any
): string {
    const category = categoryArr[this.point.index];

    const dataMap: { [key: string]: DataItem } = {};

    data?.forEach((item: any) => {
        if (!dataMap[item.name]) {
            dataMap[item.name] = {
                label: item.name,
                consumption: null,
                supply: null,
                color: item.color,
            };
        }
        if (item.stack === 'consumption') {
            dataMap[item.name].consumption = item.data[this.point.index][1];
        } else {
            dataMap[item.name].supply = item.data[this.point.index][1];
        }
    });

    const modifiedData: DataItem[] = Object.keys(dataMap).map((key) => dataMap[key]);
    const componentString = ReactDOMServer.renderToString(
        <ChartTooltip
            data={modifiedData}
            category={category}
            unit={unit}
            selectedTimePeriod={selectedTimePeriod}
            timeZone={timeZone}
        />
    );
    return componentString;
}

function lineChartTooltipFormatter(
    this: any,
    data: any[],
    categoryArr: any,
    unit: string,
    selectedTimePeriod: string,
    timeZone: any
): string {
    const category = categoryArr[this.point.index];
    const convertedData: any[] = [];

    data.forEach((series: { data: any[]; name: any; color: any }) => {
        series.data.forEach((point: any[]) => {
            if (point[0] === category) {
                const consumption = point[2];
                const supply = point[3];

                convertedData.push({
                    label: series.name,
                    consumption: consumption,
                    supply: supply,
                    color: series.color,
                });
            }
        });
    });

    const componentString = ReactDOMServer.renderToString(
        <ChartTooltip
            data={convertedData}
            category={category}
            unit={unit}
            selectedTimePeriod={selectedTimePeriod}
            timeZone={timeZone}
        />
    );
    return componentString;
}

function getDayOfWeek(timestamp: number): string {
    const date = new Date(timestamp);
    // Extract the day of the week according to the local timezone
    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
    return dayOfWeek;
}

const handleCurrentTimeValue = (selectedTime: string, index: number): number => {
    if (selectedTime === 'today') {
        return new Date().getTime();
    } else if (selectedTime === 'week') {
        return index;
    }
    return -1;
};

const getPositioner = function (this: any, labelWidth: any, labelHeight: any, point: any): any {
    let x = point.plotX + this.chart.plotLeft + 10; // Position to the right
    const y = point.plotY + this.chart.plotTop - labelHeight / 2;

    // Ensure tooltip stays within the chart container
    if (x + labelWidth > this.chart.chartWidth) {
        x = this.chart.chartWidth - labelWidth - 10; // Position to the left if it overflows
    }
    return { x: x, y: y };
};

const getStackedBarChartLabelFormatter = function (this: any, data: any): any {
    return `${this.value} ${data?.unit}`;
};

const getLineChartLabelFormatter = function (this: any, data: any): string {
    if (Math.abs(Number(this.value)) === 0) {
        return `<span style="font-weight: bold;">${Math.abs(Number(this.value))} ${data?.unit}</span>`;
    }
    return `${Math.abs(Number(this.value))} ${data?.unit}`;
};

const dayNightIcons = {
    day: {
        web: {
            name: 'WbSunny',
            family: 'material-ui',
        },
        mobile: {
            name: 'WbSunny',
            family: 'material-ui',
        },
        additionalProps: {
            style: {
                color: '#A59116',
            },
        },
    },
    night: {
        web: {
            name: 'NightlightRound',
            family: 'material-ui',
        },
        mobile: {
            name: 'NightlightRound',
            family: 'material-ui',
        },
        additionalProps: {
            style: {
                color: '#1C77CD',
            },
        },
    },
};

const renderIconToString = (iconName: string, family: string, style: any): string => {
    const iconElement = <CustomIcon iconName={iconName} family={family} iconProps={style} />;
    return renderToStaticMarkup(iconElement);
};

const getInterpolatedIndex = (timestamp: number, timeArray: number[], offset: number, chartType?: string): number => {
    const targetTime = new Date(timestamp).getTime() - offset;
    for (let i = 0; i < timeArray?.length - 1; i++) {
        const current = timeArray[i];
        const next = timeArray[i + 1];
        if (targetTime >= current && targetTime <= next) {
            const ratio = (targetTime - current) / (next - current);
            if (chartType === 'line') {
                return Math.ceil(i + ratio);
            }
            return i + ratio;
        }
    }
    return -1; // Return -1 if the timestamp is out of bounds
};

export const StackedBarChart = ({
    chartData,
    selectedTimePeriod,
    dateFormat,
    timeZone,
}: {
    chartData: ChartData;
    selectedTimePeriod: string;
    dateFormat: string;
    timeZone: string;
}): React.JSX.Element => {
    const timezoneOffset = getTimeZoneOffset(timeZone ?? systemTimezone) / 60000;
    const chartRef: any = useRef(null);
    const [chartOptions, setChartOptions] = React.useState<Highcharts.Options>({
        chart: {
            animation: false,
            type: 'column',
            backgroundColor: '#f4f4f4',
            zoomType: 'x',
            zooming: {
                mouseWheel: {
                    enabled: true,
                    sensitivity: 1.1,
                    type: 'x',
                },
            },
        } as any,
        title: {
            text: '',
        },
        time: {
            timezoneOffset,
        },
        xAxis: {
            type: selectedTimePeriod === 'today' ? 'datetime' : 'category',
            labels: {
                format: selectedTimePeriod === 'today' ? `{value:%H:%M}` : '{value}',
            },
            categories: [],
            plotLines: [],
        },
        yAxis: {
            title: {
                text: '',
            },
            labels: {
                formatter: function (data: any) {
                    return getStackedBarChartLabelFormatter.call(this, data);
                },
            },
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                borderWidth: 0,
                shadow: false,
                pointPadding: 0.1,
                groupPadding: 0.3,
            },
            series: {
                animation: false,
            },
        },
        series: [],
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    });

    React.useEffect(() => {
        const chart = chartRef?.current?.chart;
        if (chart) {
            const data = JSON.parse(JSON.stringify(chartData));
            const convertedSeries = data?.series?.map((item: { data: any[] }) => ({
                ...item,
                data: item.data.map((el) => getSeriesData(selectedTimePeriod, el, dateFormat, timeZone)),
            }));
            const xAxisData = convertedSeries?.[0]?.data?.map((el: number[]) => el[0]);

            setChartOptions({
                chart: {
                    animation: false,
                    type: 'column',
                    backgroundColor: '',
                },
                time: {
                    timezoneOffset,
                },
                xAxis: {
                    type: selectedTimePeriod === 'today' ? 'datetime' : 'category',
                    categories: xAxisData,
                    labels: {
                        format: selectedTimePeriod === 'today' ? `{value:%H:%M}` : '{value}',
                    },
                    credits: {
                        enabled: false,
                    },
                    tickInterval: selectedTimePeriod === 'today' ? 2 : null,
                    plotLines: [
                        {
                            color: '#515851',
                            width: 2,
                            value: data.forecastDataStartIndex - 1,
                            label: {
                                useHTML: true,
                                text: 'CURRENT TIME',
                                align: 'left',
                                rotation: 0,
                                x: -70,
                                style: {
                                    color: '#fffbef',
                                    backgroundColor: '#515851',
                                    height: '25px',
                                    borderRadius: '20px',
                                    textAlign: 'center',
                                    fontWeight: '530',
                                    paddingLeft: '15px',
                                    paddingRight: '15px',
                                    paddingTop: '5px',
                                    paddingBottom: '25px',
                                    zIndex: 999,
                                },
                            },
                            zIndex: 999,
                        },
                    ],
                    plotBands:
                        selectedTimePeriod === 'today' &&
                        chartData?.astro?.sunrise !== null &&
                        chartData?.astro?.sunset !== null
                            ? [
                                  {
                                      from: -1,
                                      to: getInterpolatedIndex(chartData?.astro?.sunrise, xAxisData, timezoneOffset),
                                      color: 'rgba(244, 249, 251, 1)',
                                      label: {
                                          useHTML: true,
                                          text: renderIconToString(
                                              dayNightIcons.night.web.name,
                                              dayNightIcons.night.web.family,
                                              dayNightIcons.night.additionalProps
                                          ),
                                          align: 'left', // Align text to left
                                          x: 10,
                                          y: 15,
                                      },
                                  },
                                  {
                                      from: getInterpolatedIndex(chartData?.astro?.sunrise, xAxisData, timezoneOffset),
                                      to: getInterpolatedIndex(chartData?.astro?.sunset, xAxisData, timezoneOffset),
                                      color: 'rgba(255, 251, 239, 1)',
                                      label: {
                                          useHTML: true,
                                          text: renderIconToString(
                                              dayNightIcons.day.web.name,
                                              dayNightIcons.day.web.family,
                                              dayNightIcons.day.additionalProps
                                          ),
                                          align: 'left', // Align text to left
                                          x: 10,
                                          y: 15,
                                      },
                                  },
                                  {
                                      from: getInterpolatedIndex(chartData?.astro?.sunset, xAxisData, timezoneOffset),
                                      to: xAxisData?.length - 1,
                                      color: 'rgba(244, 249, 251, 1)',
                                      label: {
                                          useHTML: true,
                                          text: renderIconToString(
                                              dayNightIcons.night.web.name,
                                              dayNightIcons.night.web.family,
                                              dayNightIcons.night.additionalProps
                                          ),
                                          align: 'left', // Align text to left
                                          x: 10,
                                          y: 15,
                                      },
                                  },
                              ]
                            : [],
                } as any,
                yAxis: {
                    // min: data?.yAxis.min,
                    // max: data?.yAxis.max,
                    // tickInterval: data?.yAxis.tickInterval,
                    gridLineDashStyle: 'Dash',
                    labels: {
                        formatter: function () {
                            return getStackedBarChartLabelFormatter.call(this, data);
                        },
                    },
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        borderWidth: 0,
                        shadow: false,
                        pointPadding: 0.1,
                        groupPadding: 0.3,
                    },
                    series: {
                        marker: {
                            enabled: false,
                        },
                        animation: false,
                        label: {
                            connectorAllowed: false,
                        },
                    },
                },
                series: chartData?.series?.map((s: any) => ({
                    type: 'column',
                    name: s.name,
                    color: s.color,
                    data:
                        s.data?.map((point: any, index: number) => ({
                            y: point[1],
                            color:
                                data.forecastDataStartIndex !== -1 && index >= data.forecastDataStartIndex
                                    ? s.legendBgColor
                                    : s.color,
                        })) ?? [],
                    stack: s.stack,
                })),
                legend: {
                    enabled: false,
                },
                tooltip: {
                    useHTML: true,
                    style: {
                        zIndex: 1201,
                    },
                    formatter: function () {
                        return tooltipFormatter.call(
                            this,
                            convertedSeries,
                            xAxisData,
                            data?.unit,
                            selectedTimePeriod,
                            timeZone
                        );
                    },
                    positioner: function (labelWidth, labelHeight, point) {
                        return getPositioner.call(this, labelWidth, labelHeight, point);
                    },
                    outside: true,
                    followTouchMove: false,
                },
            });
        }
    }, [chartData]);

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />;
};

interface ChartData {
    unit: string;
    series: Array<{
        name: string;
        color: string;
        data: Array<[number, number]>;
    }>;
    forecastDataStartIndex: number;
    astro?: {
        sunrise?: any;
        sunset?: any;
    };
}

interface LineChartProps {
    chartData: ChartData;
    selectedTimePeriod: string;
    dateFormat: string;
    timeZone: string;
}

export const LineChart: React.FC<LineChartProps> = ({ chartData, selectedTimePeriod, dateFormat, timeZone }) => {
    const timezoneOffset = getTimeZoneOffset(timeZone ?? systemTimezone) / 60000;
    const chartRef: any = useRef(null);
    const [options, setOptions] = useState<any>({
        chart: {
            animation: false,
            type: 'spline',
            inverted: true,
            height: '100%',
            zoomType: 'x',
            zooming: {
                mouseWheel: {
                    enabled: true,
                    sensitivity: 1.1,
                    type: 'x',
                },
            },
        },
        title: {
            text: '',
        },
        time: {
            timezoneOffset,
        },
        xAxis: {
            type: selectedTimePeriod === 'today' ? 'datetime' : 'category',
            reversed: false,
            labels: {
                format: selectedTimePeriod === 'today' ? `{value:%H:%M}` : '{value}',
                rotation: 0,
                align: 'right',
                x: -2,
                y: 5,
                overflow: 'visible',
                style: {
                    opacity: 1,
                },
            },

            tickInterval: selectedTimePeriod === 'today' ? 2 * 3600 * 1000 : null,
            startOnTick: true,
            endOnTick: true,
            lineWidth: 0,
            gridLineWidth: 0,
        },
        yAxis: {
            lineWidth: 1,
            lineColor: '#000',
            gridLineDashStyle: 'Dash',
            title: {
                enabled: false,
            },
            accessibility: {
                description: 'Energy consumption and supply',
            },
            plotLines: [
                {
                    color: 'black',
                    width: 2,
                    value: 0,
                    dashStyle: 'Solid',
                    zIndex: 5,
                },
            ],
            gridLineWidth: 1,
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false,
                },
                animation: false,
                label: {
                    connectorAllowed: false,
                },
            },
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            shared: true,
            useHTML: true,
            outside: true,
            followTouchMove: false,
        },
        credits: {
            enabled: false,
        },
    });

    useEffect(() => {
        const chart = chartRef?.current?.chart;
        if (chart) {
            const data = JSON.parse(JSON.stringify(chartData ?? {}));
            const convertedSeries = data?.series?.map((item: { data: any[] }) => ({
                ...item,
                data: item.data.map((el) => getSeriesData(selectedTimePeriod, el, dateFormat, timeZone)),
            }));
            const xAxisData = convertedSeries?.[0]?.data?.map((el: number[]) => el[0]);
            const newOptions: Highcharts.Options = {
                chart: {
                    animation: false,
                    type: 'spline',
                    inverted: true,
                    height: 600,
                    zoomType: 'x',
                    zooming: {
                        mouseWheel: {
                            enabled: true,
                            sensitivity: 1.1,
                            type: 'x',
                        },
                    },
                } as any,
                time: {
                    timezoneOffset,
                },
                title: {
                    text: '',
                },
                xAxis: {
                    type: selectedTimePeriod === 'today' ? 'datetime' : 'category',
                    reversed: false,
                    categories: xAxisData,
                    labels: {
                        format: selectedTimePeriod === 'today' ? `{value:%H:%M}` : '{value}',
                        rotation: 0,
                        align: 'right',
                        x: -2,
                        y: 5,
                        overflow: 'visible',
                        style: {
                            opacity: 1,
                        },
                    },
                    tickInterval: selectedTimePeriod === 'today' ? 2 * 3600 * 1000 : null,
                    startOnTick: true,
                    endOnTick: true,
                    lineWidth: 0,
                    gridLineWidth: 0,
                    plotLines: [
                        {
                            value: handleCurrentTimeValue(selectedTimePeriod, xAxisData?.length - 1),
                            color: '#515851',
                            zIndex: 10,
                            label: {
                                useHTML: true,
                                text: 'CURRENT TIME',
                                align: 'right',
                                rotation: 0,
                                x: -100,
                                y: 0,
                                style: {
                                    color: '#fffbef',
                                    backgroundColor: '#515851',
                                    height: '25px',
                                    borderRadius: '20px',
                                    textAlign: 'center',
                                    fontWeight: '530',
                                    padding: '5px 15px',
                                    paddingLeft: '15px',
                                    paddingRight: '15px',
                                    paddingTop: '5px',
                                    paddingBottom: '25px',
                                    zIndex: 999,
                                    position: 'absolute',
                                },
                            },
                        },
                    ],
                    plotBands: [
                        {
                            color: 'rgba(0, 0, 0, 0)',
                            from: xAxisData?.[0],
                            to: xAxisData?.[xAxisData.length - 1],
                            label: {
                                text: 'ENERGY CONSUMPTION',
                                align: 'left',
                                verticalAlign: 'bottom',
                                y: -10,
                                style: {
                                    color: '#424E54',
                                    fontSize: '15px',
                                    fontWeight: '500',
                                },
                            },
                        },
                        {
                            color: 'rgba(0, 0, 0, 0)',
                            from: xAxisData?.[0],
                            to: xAxisData?.[xAxisData.length - 1],
                            label: {
                                text: 'ENERGY SUPPLY',
                                align: 'right',
                                verticalAlign: 'bottom',
                                y: -10,
                                x: -10,
                                style: {
                                    color: '#424E54',
                                    fontSize: '15px',
                                    fontWeight: '500',
                                },
                            },
                        },
                        ...(selectedTimePeriod === 'today' &&
                        chartData?.astro?.sunrise !== null &&
                        chartData?.astro?.sunset !== null
                            ? [
                                  {
                                      from: xAxisData?.[0],
                                      to: xAxisData?.[
                                          getInterpolatedIndex(
                                              chartData?.astro?.sunrise,
                                              xAxisData,
                                              timezoneOffset,
                                              'line'
                                          )
                                      ],
                                      color: 'rgba(244, 249, 251, 1)',
                                      label: {
                                          useHTML: true,
                                          text: renderIconToString(
                                              dayNightIcons.night.web.name,
                                              dayNightIcons.night.web.family,
                                              dayNightIcons.night.additionalProps
                                          ),
                                          align: 'left',
                                          x: 10,
                                          y: 15,
                                      },
                                  },
                                  {
                                      from: xAxisData?.[
                                          getInterpolatedIndex(
                                              chartData?.astro?.sunrise,
                                              xAxisData,
                                              timezoneOffset,
                                              'line'
                                          )
                                      ],
                                      to: xAxisData?.[
                                          getInterpolatedIndex(
                                              chartData?.astro?.sunset,
                                              xAxisData,
                                              timezoneOffset,
                                              'line'
                                          )
                                      ],
                                      color: 'rgba(255, 251, 239, 1)',
                                      label: {
                                          useHTML: true,
                                          text: renderIconToString(
                                              dayNightIcons.day.web.name,
                                              dayNightIcons.day.web.family,
                                              dayNightIcons.day.additionalProps
                                          ),
                                          align: 'left',
                                          x: 10,
                                          y: 15,
                                      },
                                  },
                                  {
                                      from: xAxisData?.[
                                          getInterpolatedIndex(
                                              chartData?.astro?.sunset,
                                              xAxisData,
                                              timezoneOffset,
                                              'line'
                                          )
                                      ],
                                      to: xAxisData?.[xAxisData?.length - 1],
                                      color: 'rgba(244, 249, 251, 1)',
                                      label: {
                                          useHTML: true,
                                          text: renderIconToString(
                                              dayNightIcons.night.web.name,
                                              dayNightIcons.night.web.family,
                                              dayNightIcons.night.additionalProps
                                          ),
                                          align: 'left',
                                          x: 10,
                                          y: 15,
                                      },
                                  },
                              ]
                            : []),
                    ],
                } as any,
                yAxis: {
                    lineWidth: 1,
                    lineColor: '#000',
                    gridLineDashStyle: 'Dash',
                    min: data?.minMaxValue ? Number(-data?.minMaxValue) + Number(-data?.minMaxValue) / 10 : null,
                    max: data?.minMaxValue ? Number(data?.minMaxValue) + Number(data?.minMaxValue) / 10 : null,
                    title: {
                        enabled: false,
                    } as any,
                    labels: {
                        formatter: function () {
                            return getLineChartLabelFormatter.call(this, data);
                        },
                    },
                    accessibility: {
                        description: 'Energy consumption and supply',
                    },
                    plotLines: [
                        {
                            color: 'black',
                            width: 2,
                            value: 0,
                            dashStyle: 'Solid',
                            zIndex: 5,
                        },
                    ],
                    gridLineWidth: 1,
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false,
                        },
                        animation: false,
                        label: {
                            connectorAllowed: false,
                        },
                    },
                },
                series: convertedSeries?.map((s: any) => ({
                    type: 'spline',
                    name: s.name,
                    color: s.color,
                    data: s.data,
                    dashStyle: s.name.toLowerCase() === 'delta' ? 'Dash' : 'Solid',
                    zoneAxis: 'x',
                    zones: [
                        {
                            value:
                                s.name.toLowerCase() === 'delta'
                                    ? s.data?.[0]?.[0]
                                    : s.data?.[data.forecastDataStartIndex]?.[0],
                        },
                        {
                            dashStyle: 'Dash',
                        },
                    ],
                })),
                legend: {
                    enabled: false,
                },
                tooltip: {
                    shared: true,
                    useHTML: true,
                    style: {
                        zIndex: 1201,
                    },
                    formatter: function () {
                        return lineChartTooltipFormatter.call(
                            this,
                            convertedSeries,
                            xAxisData,
                            data?.unit,
                            selectedTimePeriod,
                            timeZone
                        );
                    },
                    positioner: function (labelWidth, labelHeight, point) {
                        return getPositioner.call(this, labelWidth, labelHeight, point);
                    },
                    outside: true,
                    followTouchMove: false,
                },
                credits: {
                    enabled: false,
                },
            };

            setOptions(newOptions);
        }
    }, [chartData]);

    return <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />;
};
