import { useState, useEffect } from 'react';
import { useGetFilterActionsQuery } from '../features/actions/actionsApi';
export const useTransformActionData = (deviceTypes: any): any => {
    const [allActions, setAllActions] = useState<any>([]);

    const { data, isLoading, isFetching }: any = useGetFilterActionsQuery(
        { body: { deviceTypeIds: deviceTypes?.ids }, params: { all: deviceTypes?.allDeviceTypes } },
        {
            refetchOnMountOrArgChange: true,
            skip: !deviceTypes?.ids?.length && !deviceTypes?.allDeviceTypes,
        }
    );

    useEffect(() => {
        if (data?.data) {
            const dataClone = JSON.parse(JSON.stringify(data?.data ?? []));
            const LoadOn: any = [];
            const LoadOff: any = [];
            dataClone.forEach((element: any) => {
                if (element?.category === 'Load') {
                    LoadOff.push(element);
                    LoadOn.push(element);
                }
            });
            if (LoadOff?.length) {
                setAllActions([
                    ...dataClone.filter((ele: any) => ele?.category !== 'Load'),
                    {
                        displayName: 'Load On',
                        key: 'loadOn',
                        isLoadEnabled: true,
                        values: LoadOn,
                        icon: {
                            web: {
                                name: 'Outlet',
                                family: 'material-ui',
                            },
                            mobile: {
                                name: 'outlet',
                                family: 'material',
                            },
                        },
                    },
                    {
                        displayName: 'Load Off',
                        icon: {
                            web: {
                                name: 'Outlet',
                                family: 'material-ui',
                            },
                            mobile: {
                                name: 'outlet',
                                family: 'material',
                            },
                        },
                        key: 'loadOff',
                        isLoadEnabled: false,
                        values: LoadOff,
                    },
                ]);
            } else {
                setAllActions(dataClone);
            }
        }
    }, [data]);

    return {
        allActions,
        isLoading: isFetching || isLoading,
    };
};
