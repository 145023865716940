import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { handleReallocateChannels } from '../widgetManagement/helpers';

const initialState = {
    paginationPayload: {
        page: 0,
        size: 10,
    },
    channels: [],
    modalLoader: true,
};

const featuredChannelsSlice = createSlice({
    name: 'featuredChannels',
    initialState: initialState,
    reducers: {
        resetFeaturedChannelsPayload: () => {
            return initialState;
        },
        setModelsPaginationPayload: (state, action: PayloadAction<any>) => {
            state.paginationPayload = action.payload;
        },
        setModelGlobalSearch: (state: any, action: PayloadAction<any>) => {
            state['paginationPayload']['seachKey'] = 'name';
            state['paginationPayload']['searchValue'] = action.payload;
        },
        setModelChannelKeys: (state: any, action: PayloadAction<any>) => {
            const channelsClone = current(state?.channels);
            const existingIndex = channelsClone?.findIndex(
                (item: any) => item?.channelId === action.payload?.channelId
            );
            if (existingIndex === -1) {
                state['channels']?.push(action.payload);
            } else {
                state['channels']?.splice(existingIndex, 1);
            }
        },
        reAllocateModelChannels: (state: any, action: PayloadAction<any>) => {
            handleReallocateChannels(state, action);
        },
        setModalLoader: (state: any, action: PayloadAction<boolean>) => {
            state.modalLoader = action.payload;
        },
    },
});

export const {
    setModelsPaginationPayload,
    setModelGlobalSearch,
    setModelChannelKeys,
    reAllocateModelChannels,
    setModalLoader,
    resetFeaturedChannelsPayload,
} = featuredChannelsSlice.actions;

export default featuredChannelsSlice;
