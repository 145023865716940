import React, { useEffect, useRef, useContext, ReactElement } from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useTreeContext, useDragNode, useDropNode, useDragControl, useDragHandle } from './hooks';
import { PlaceholderContext } from './providers';
import { NodeModel, RenderParams } from './types';
import { Container } from './Container';
import { useTheme } from '@mui/material/styles';

export const Node = <T,>(props: any): ReactElement | null => {
    const theme: any = useTheme();
    const treeContext = useTreeContext();
    const placeholderContext = useContext(PlaceholderContext);
    const containerRef = useRef<HTMLLIElement>(null);
    const handleRef = useRef<any>(null);
    const item = props as NodeModel<T>;
    const { expandedItems, controlledExpandedNodes, classes } = treeContext;
    const open = controlledExpandedNodes?.includes?.(props.id) ?? expandedItems?.includes?.(props.id) ?? false;

    const [isMouseOver, setIsMouseOver] = React.useState(false);

    const [isDragging, drag, preview] = useDragNode(item, containerRef);
    const [isOver, , drop] = useDropNode<T>(item, props?.parentNode, containerRef, props?.disabled || isDragging);

    useDragHandle(containerRef, handleRef, drag);

    drop(containerRef);

    useEffect(() => {
        if (treeContext.dragPreviewRender) {
            preview(getEmptyImage(), { captureDraggingState: true });
        } else if (handleRef.current) {
            preview(containerRef);
        }
    }, [preview, treeContext.dragPreviewRender]);

    useDragControl(containerRef);

    let className = classes?.listItem || {};

    if (isOver && classes?.dropTarget) {
        className = { ...className, ...classes.dropTarget };
    }

    if (isDragging && classes?.draggingSource) {
        className = { ...className, ...classes.draggingSource };
    }

    const draggable = treeContext.canDrag ? treeContext.canDrag(props.id) : true;
    const isDropTarget = placeholderContext.dropTargetId === props.id;

    const params: RenderParams = {
        depth: props.depth,
        isOpen: open,
        isDragging,
        isDropTarget,
        draggable,
        containerRef,
        handleRef,
        controlledSelected: props?.controlledSelected,
        filterPayload: props?.filterPayload,
    };
    const [hierarchyMappingDataId, setHierarchyMappingDataId] = React.useState(props?.id ?? '');

    useEffect(() => {
        if (typeof treeContext?.hierarchyMappingData?.[props?.id] === 'string') {
            setHierarchyMappingDataId(treeContext?.hierarchyMappingData?.[props?.id] ?? '');
        } else {
            setHierarchyMappingDataId(props?.id ?? '');
        }
    }, [treeContext?.hierarchyMappingData]);

    return (
        <li
            className={treeContext?.dnd ? `dnd-hierarchy${isMouseOver ? ' bg-content-wrapper' : ' bg-white'}` : ''}
            ref={!props?.disabled ? containerRef : undefined}
            style={{
                ...className,
                ...(isDropTarget &&
                !isDragging &&
                !treeContext?.hierarchyMappingData?.[hierarchyMappingDataId]?.length &&
                !props?.disabled
                    ? {
                          outline: `3px solid ${theme?.palette?.primary?.main}`,
                          backgroundColor: '#007BC10D',
                          color: theme?.palette?.primary?.main,
                      }
                    : {}),
                ...(treeContext?.dnd ? { margin: '3px' } : {}),
            }}
            role="listitem"
        >
            {treeContext.render(item, props?.parentNode, params, { isMouseOver, setIsMouseOver })}
            {!treeContext?.loadingNode?.includes?.(props?.id) &&
                treeContext?.hierarchyMappingData?.[hierarchyMappingDataId] &&
                (controlledExpandedNodes?.includes?.(props?.id) ?? expandedItems?.includes?.(props?.id) ?? false) && (
                    <Container
                        parentNode={props}
                        depth={props.depth + 1}
                        nodes={treeContext?.hierarchyMappingData?.[hierarchyMappingDataId]}
                        controlledSelected={
                            treeContext?.autoChildSelection
                                ? (props?.controlledSelected || treeContext?.selectedNodes?.includes(props?.id)) ??
                                  false
                                : false
                        }
                        disabled={isDragging || props?.disabled}
                    />
                )}
        </li>
    );
};
