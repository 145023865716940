import React, { useEffect, useState } from 'react';
import { TextField, Stack, Typography, InputAdornment, IconButton, Button, useTheme } from '@mui/material';
import { Autocomplete } from '@react-google-maps/api';
import { LocationType, fetchLocation, MapComponent } from '../../WidgetManagement/Widgets/map/Component';
import MyLocation from '@mui/icons-material/MyLocation';
import Search from '@mui/icons-material/Search';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setUpdateProperties } from '@fiji/common/src/features/deviceManagement/deviceSlice';
import { DECIMAL_REGEX } from '@fiji/common';
import { CustomTransComponent } from 'components';

export const LocationUpdateModal = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const theme: any = useTheme();
    const [location, setLocation] = useState<LocationType>({
        lat: null,
        lng: null,
    });
    const [coordinates, setCoordinates] = useState<LocationType>({
        lat: null,
        lng: null,
    });
    const [autocomplete, setAutocomplete] = useState<any>(null);
    const [isAutocomplete, setIsAutocomplete] = useState(false);
    const [locationName, setLocationName] = useState('');
    const googleMapLoaded = useTypedSelector((state) => state.common.googleMapLoaded);
    const { propertiesPayload } = useTypedSelector((state) => state.device);

    useEffect(() => {
        if (propertiesPayload?.location) {
            setLocation(propertiesPayload?.location);
            setCoordinates(propertiesPayload?.location);
        }
    }, []);

    const locationChangeHandler = (e: any): void => {
        if (DECIMAL_REGEX.test(e.target.value) || !e?.target?.value) {
            setLocation((prev: any) => ({
                ...prev,
                [e?.target?.name]: e?.target?.value ? +e?.target?.value : null,
            }));
        }
    };

    const handleMapClick = (): void => {
        try {
            if (coordinates?.lat && coordinates?.lng) {
                const geocoder = new google.maps.Geocoder();
                geocoder
                    .geocode({ location: coordinates as any })
                    .then((response) => {
                        setLocationName(response.results[0]?.formatted_address ?? '');
                        setIsAutocomplete(true);
                    })
                    .catch(() => {});
            }
        } catch (error) {
            throw new Error('Error fetching location details');
        }
    };

    useEffect(() => {
        if (coordinates?.lat && !isAutocomplete) handleMapClick() as any;
    }, [coordinates, isAutocomplete]);

    useEffect(() => {
        dispatch(
            setUpdateProperties({
                key: 'location',
                value: {
                    lat: coordinates?.lat ? +coordinates?.lat : null,
                    lng: coordinates?.lng ? +coordinates?.lng : null,
                },
            })
        );
    }, [coordinates]);

    const onPlaceSelect = (): void => {
        setLocationName(autocomplete?.getPlace()?.formatted_address);
        const lat = parseFloat(autocomplete?.getPlace()?.geometry?.location.lat()) ?? 0;
        const lng = parseFloat(autocomplete?.getPlace()?.geometry.location.lng()) ?? 0;
        setCoordinates({ lat, lng });
        setLocation({ lat, lng });
    };

    const getLocation = (): void => {
        void fetchLocation().then(({ lat, lng }: any) => {
            setCoordinates({ lat: parseFloat(lat) ?? 0, lng: parseFloat(lng) ?? 0 });
            setIsAutocomplete(false);
            setLocation({ lat: parseFloat(lat) ?? 0, lng: parseFloat(lng) ?? 0 });
        });
    };

    return (
        <>
            <Typography variant="body1">
                <CustomTransComponent translationKey={'COMMON:ENTER_LOCATION'} />
            </Typography>
            <Stack
                direction={'row'}
                pb={2}
                my={2}
                width={'100%'}
                justifyContent={'space-between'}
                className="border-bottom-1"
            >
                {googleMapLoaded && (
                    <Autocomplete
                        className="w-100"
                        onLoad={(autocompleteData: any): void => {
                            setAutocomplete(autocompleteData);
                        }}
                        onPlaceChanged={onPlaceSelect}
                    >
                        <Stack direction={'row'}>
                            {locationName ? (
                                <TextField
                                    data-cy="location"
                                    onChange={(event): void => {
                                        setLocationName(event.target.value);
                                        setIsAutocomplete(true);
                                    }}
                                    value={locationName ?? ''}
                                    label="Location"
                                    sx={{
                                        '& .Mui-focused': {
                                            color: '#424e54',
                                        },
                                        '& .Mui-focused:after': {
                                            borderBottom: 'none',
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    id="my-location"
                                                    aria-label="location"
                                                    onClick={getLocation}
                                                >
                                                    <MyLocation id="myLocation" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="filled"
                                    fullWidth
                                    focused
                                />
                            ) : (
                                <TextField
                                    data-cy="location"
                                    label={<CustomTransComponent translationKey={'COMMON:LOCATION'} />}
                                    variant="filled"
                                    fullWidth
                                    onChange={(): void => {
                                        setIsAutocomplete(true);
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    id="my-location"
                                                    aria-label="location"
                                                    onClick={getLocation}
                                                >
                                                    <MyLocation />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        </Stack>
                    </Autocomplete>
                )}
            </Stack>
            <Stack direction={'row'} spacing={2} width={'100%'}>
                <Stack justifyContent={'space-between'} width={'50%'}>
                    <Stack spacing={2}>
                        <TextField
                            id="lat"
                            label={<CustomTransComponent translationKey={'COMMON:LAT'} />}
                            name="lat"
                            variant="filled"
                            value={location?.lat ?? ''}
                            onChange={locationChangeHandler}
                            fullWidth
                        />
                        <TextField
                            id="lng"
                            label={<CustomTransComponent translationKey={'COMMON:LNG'} />}
                            name="lng"
                            variant="filled"
                            value={location?.lng ?? ''}
                            onChange={locationChangeHandler}
                            fullWidth
                        />
                    </Stack>
                    <Button
                        id="search"
                        variant="outlined"
                        onClick={(): void => {
                            setCoordinates(location);
                            setIsAutocomplete(false);
                        }}
                        disabled={!location?.lat || !location?.lng}
                        sx={{
                            border: `1px solid ${theme?.palette?.primary?.main}`,
                            '&:hover': {
                                backgroundColor: theme?.palette?.primary?.[50],
                            },
                        }}
                        startIcon={<Search />}
                    >
                        <CustomTransComponent translationKey={'COMMON:SEARCH_PLACEHOLDER'} />
                    </Button>
                </Stack>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    height={'232px'}
                    width={'365px'}
                >
                    {googleMapLoaded && (
                        <MapComponent
                            widgetData={{ secondary: [{ coordinates }] }}
                            clustering={false}
                            showInfoWindow={false}
                            markerSelector
                        />
                    )}
                </Stack>
            </Stack>
        </>
    );
};
