import React from 'react';
import { EventNote } from '@mui/icons-material';
import { ModalActions } from 'components/CustomModal';
import { Avatar, Chip, Stack, Typography } from '@mui/material';
import { ChannelValue } from '@brightlayer-ui/react-components';
import { useTheme } from '@mui/material/styles';
import { CustomModal } from 'components';
import { useConfirm } from '@fiji/common/src/hooks';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from '@fiji/common';
import { useGetScheduleByIdQuery } from '@fiji/common/src/features/schedule/scheduleApi';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { useGetApplicationConfigQuery } from '@fiji/common/src/features/userManagement/userApi';
import { ApiEndpointType, ApiResponseType } from '@fiji/common/src/features/common/commonTypes';
import { Profile } from '@fiji/common/src/types';
import { decryptAES } from 'utils/helpers';
import { holidayCodes } from '@fiji/common/src/constants/holidayCountryCodes';
import { useRBAC } from 'hooks';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';

export const ScheduleDetails = React.forwardRef((props: any, ref): JSX.Element => {
    const theme: any = useTheme();
    const modalId = React.useId();
    const modalRef = React.useRef<any>(null);
    const currentRealmName = useTypedSelector((state) => state['common']['selectedRealm']);
    const [holidayChips, setHolidayChips] = React.useState<any>();

    const { data: profileData } = useGetUserProfileQuery({}, { skip: !currentRealmName }) as ApiEndpointType<
        ApiResponseType<Profile>
    >;

    const { data: applicationConfig } = useGetApplicationConfigQuery(
        {},
        {
            skip: !profileData,
        }
    );

    const navigate = useNavigate();

    const { isVisible, onCancel, onClick, data, onConfirm } = useConfirm((): any => {
        navigate(`/${currentRealmName}/schedule/${data?.id}`);
    });

    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);

    const canEditSchedule = hasPermission('edit-schedules');

    const fetchAndSetHolidays = (params: any): void => {
        const currentYear = new Date().getFullYear();
        const timeMin = new Date(`${currentYear}-01-01`).toISOString();
        const timeMax = new Date(`${currentYear}-12-31`).toISOString();

        fetch(
            `https://www.googleapis.com/calendar/v3/calendars/${holidayCodes[params]}/events?key=${
                decryptAES(applicationConfig?.data, {
                    userId: profileData?.data?.id,
                    requestId: applicationConfig?.requestId,
                }).google
            }&timeMin=${timeMin}&timeMax=${timeMax}`
        )
            .then((response) => response?.json())
            ?.then((res) => {
                const newChips: any = [];
                res?.items?.forEach((item: any) => {
                    currentData?.data?.excludeHolidays?.forEach((holiday: any) => {
                        if (holiday.toString() === new Date(item?.start?.date)?.getTime().toString()) {
                            newChips.push({
                                id: item?.id,
                                date: item?.start?.date,
                                label: item?.summary,
                            });
                        }
                    });
                });
                setHolidayChips(newChips);
            })
            .catch((err) => console.error(err));
    };

    const { isFetching, isLoading, currentData } = useGetScheduleByIdQuery(
        { scheduleId: data?.id },
        { skip: !data?.id }
    );

    React.useEffect(() => {
        if (currentData?.data) {
            if (currentData?.data?.exceptionHolidayCountry) {
                fetchAndSetHolidays(currentData?.data?.exceptionHolidayCountry);
            } else if (currentData?.data?.excludeHolidays && !currentData?.data?.exceptionHolidayCountry) {
                setHolidayChips(
                    currentData?.data?.excludeHolidays?.map((item: any) => ({
                        id: item,
                        label: moment(item).format('LL'),
                    }))
                );
            }
        }
    }, [currentData]);

    // const modalActions: ModalActions[] = [
    //     {
    //         key: 'edit',
    //         label: 'Edit',
    //         close: true,
    //         variant: 'outlined',
    //         handleClick: onConfirm,
    //     },
    //     {
    //         key: 'close',
    //         label: 'Close',
    //         close: true,
    //         variant: 'contained',
    //         handleClick: (): void => onCancel(true),
    //     },
    // ];

    const getModalActions = (): ModalActions[] => {
        const modalData: any = [];
        if (canEditSchedule) {
            modalData.push({
                key: 'edit',
                label: 'Edit',
                close: true,
                variant: 'outlined',
                handleClick: onConfirm,
            });
        }
        modalData.push({
            key: 'close',
            label: 'Close',
            close: true,
            variant: 'contained',
            handleClick: (): void => onCancel(true),
        });

        return modalData;
    };

    React.useImperativeHandle(
        ref,
        () => ({
            handleScheduleDetails: onClick,
        }),
        []
    );

    return (
        <CustomModal
            key={modalId}
            ref={modalRef}
            isOpen={isVisible}
            isLoading={isLoading || isFetching}
            type="secondary"
            actions={getModalActions()}
            customHeader={
                <Stack alignItems={'center'} justifyContent={'center'}>
                    <Avatar sx={{ backgroundColor: theme?.palette?.primary?.[50] }} className="height-96 width-96">
                        <EventNote color="primary" className="height-56 width-56" />
                    </Avatar>
                </Stack>
            }
            noDividers
            actionsDivider
        >
            <Stack className="max-height-410 overflow-y-scroll">
                <Typography variant="h6" fontSize={'20px'} fontWeight={'600'}>
                    {currentData?.data?.name ?? '-'}
                </Typography>
                <Stack mt={2}>
                    <Typography variant="body1">
                        View all asset(s) action and exceptions for a scheduled event.
                    </Typography>
                    <Typography variant="body1">
                        Choose “Close” to exit or “Edit” to modify the scheduled event.
                    </Typography>
                </Stack>

                <Stack mt={4}>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        className="border-bottom-1"
                        py={2}
                    >
                        <Typography variant="body2" fontSize={'14px'}>
                            Device(s)
                        </Typography>
                        <Stack direction={'row'} spacing={2} alignItems={'center'}>
                            {currentData?.data?.deviceIds?.map((item: any) => (
                                <Chip key={item?.id} label={item?.name} />
                            ))}
                        </Stack>
                    </Stack>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        className="border-bottom-1"
                        py={2}
                    >
                        <Typography variant="body2" fontSize={'14px'}>
                            Action
                        </Typography>
                    </Stack>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        className="border-bottom-1"
                        py={2}
                        pl={3}
                    >
                        {currentData?.data?.actions?.map((action: any) => (
                            <>
                                <Typography key={action?.id} variant="body2" fontSize={'14px'} fontWeight={'600'}>
                                    {action?.name ?? '-'}
                                    <ChannelValue value={action?.startValue} units={action?.unit} />
                                </Typography>
                            </>
                        ))}
                    </Stack>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        className="border-bottom-1"
                        py={2}
                    >
                        <Typography variant="body2" fontSize={'14px'}>
                            Schedule
                        </Typography>
                        <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                            {currentData?.data?.nextRun
                                ? `${moment(currentData?.data?.nextRun)?.format('LL')} - ${moment(
                                      currentData?.data?.actionStartTime
                                  )?.format('hh:mm a')} ${
                                      currentData?.data?.actionEndTime
                                          ? `to ${moment(currentData?.data?.actionEndTime)?.format('hh:mm a')}`
                                          : ''
                                  }`
                                : '-'}
                        </Typography>
                    </Stack>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        className="border-bottom-1"
                        py={2}
                        pl={3}
                    >
                        <Typography variant="body2" fontSize={'14px'}>
                            Frequency
                        </Typography>
                        <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                            {currentData?.data?.frequency ?? '-'}
                        </Typography>
                    </Stack>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        className="border-bottom-1"
                        py={2}
                    >
                        <Typography variant="body2" fontSize={'14px'}>
                            Exception
                        </Typography>
                        <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                            <>
                                {currentData?.data?.excludeHolidays?.length
                                    ? currentData?.data?.exceptionHolidayCountry
                                        ? 'Holiday'
                                        : 'Manual'
                                    : '-'}
                            </>
                        </Typography>
                    </Stack>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        className="border-bottom-1"
                        py={2}
                        pl={3}
                    >
                        <Typography variant="body2" fontSize={'14px'}>
                            {currentData?.data?.excludeHolidays?.length
                                ? currentData?.data?.exceptionHolidayCountry
                                    ? 'Holiday'
                                    : 'Manual'
                                : '-'}
                        </Typography>
                        <Stack
                            direction={'row'}
                            flexWrap="wrap"
                            spacing={1}
                            alignItems={'flex-end'}
                            sx={{ paddingLeft: '20px' }}
                        >
                            {currentData?.data?.excludeHolidays?.length ? (
                                holidayChips?.map((item: any) => <Chip key={item.id} label={item?.label ?? '-'} />)
                            ) : (
                                <></>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </CustomModal>
    );
});
