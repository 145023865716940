import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import {
    useLazyGetAllChannelsQuery,
    useLazyGetAllLoadsQuery,
} from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useGetAllGroupsMutation } from '@fiji/common/src/features/group/groupApi';
import React from 'react';

import { setWidgetDevice } from '@fiji/common/src/features/widgetManagement/widgetConfigurationSlice';

import { getWidgetTypeValidationConfig, retrieveChannelTypefromWidgetType } from '@fiji/common/src/utils/helpers';
import { toFirstLetterUpperCase } from 'utils/helpers';

type ReturnProps = {
    loadingHierarchyNode: any[];
    handleFetchHierarchy: (...args: any) => Promise<void>;
    hasChildNode: (...args: any) => boolean;
    hierarchyMappingData: any;
    handleCleanHierarchyNode: (...args: any) => void;
    groupsData: any;
};

export const useChannelHierarchyHandlers = ({ widgetType }: any): ReturnProps => {
    const selectedOrg = useTypedSelector((state) => state.org.selectedOrg);

    const dispatch = useAppDispatch();
    const selectedDeviceNode = useTypedSelector((state) => state.widgetConfiguration.device);
    const selectedSource = useTypedSelector((state) => state.widgetConfiguration.source);

    const [getAllLoads] = useLazyGetAllLoadsQuery();
    const [getAllChannels] = useLazyGetAllChannelsQuery();
    const [getGroupChildren, { data }] = useGetAllGroupsMutation({ fixedCacheKey: 'cachedAllGroupData' });

    const [groupsData, setGroupsData] = React.useState([]);
    const [hierarchyMappingData, setHierarchyMappingData] = React.useState({}) as any;
    const [loadingHierarchyNode, setLoadingHierarchyNode] = React.useState([]);

    React.useEffect(() => {
        if (!groupsData?.length) {
            setGroupsData(data?.data?.records);
        }
    }, [data]);

    React.useEffect(() => {
        if (widgetType && !selectedDeviceNode && !getWidgetTypeValidationConfig(widgetType)['isSourceRequired']) {
            dispatch(setWidgetDevice({ data: selectedOrg, reset: false }));
        }
    }, [groupsData, widgetType]);

    React.useEffect(() => {
        if (selectedOrg?.id) {
            void getGroupChildren({});
        }
    }, [selectedOrg?.id]);

    const handleFetchHierarchy = async (currentNode: any): Promise<void> => {
        setLoadingHierarchyNode((prevState): any => [...prevState, currentNode?.id]);
        if (currentNode?.deviceTypeId && currentNode?.type === 'DEVICE') {
            const { data: channels }: any = await getAllChannels({
                sourceId: currentNode?.deviceTypeId ?? '',
                params: {
                    channelType: retrieveChannelTypefromWidgetType(widgetType),
                },
                number: widgetType === 'gauge' || widgetType === 'charts' ? true : false,
            });
            if (channels?.success) {
                setHierarchyMappingData((prev: any) => ({
                    ...prev,
                    [currentNode?.id]: channels?.data?.length
                        ? channels?.data?.map((item: any) => ({
                              id: `${item?.channelId}${currentNode?.id}`,
                              name: item?.displayName,
                              type: 'channel',
                              ...item,
                          }))
                        : [
                              {
                                  id: 'uniqueqqrf3fd',
                                  name: `No ${toFirstLetterUpperCase(widgetType)} found`,
                                  type: 'DEVICE',
                                  noIcon: true,
                              },
                          ],
                }));
            }
        } else {
            const { data: childHierarchyData }: any = await getGroupChildren({ parent: currentNode?.id });
            if (childHierarchyData?.success) {
                setHierarchyMappingData((prev: any) => ({
                    ...prev,
                    [currentNode?.id]: [...(prev[currentNode?.id] ?? []), ...(childHierarchyData.data?.records ?? [])],
                }));
            }
        }

        setLoadingHierarchyNode((prevState): any => prevState.filter((loadingNode) => loadingNode !== currentNode?.id));
    };

    const handleFetchLoadsHierarchy = async (parentNode: any): Promise<void> => {
        setLoadingHierarchyNode((prevState): any => [...prevState, parentNode?.id]);
        if (parentNode?.deviceTypeId) {
            const { data: loadsData }: any = await getAllLoads({
                body: { page: 0, size: 10 },
                deviceId: parentNode?.id,
            });
            setHierarchyMappingData((prev: any) => ({
                ...prev,
                [parentNode?.id]: loadsData?.data?.loadConfiguration?.length
                    ? loadsData?.data?.loadConfiguration?.map((item: any) => ({
                          loadId: item?.loadId,
                          id: `${item?.loadId}${parentNode?.id}`?.replace(/-/g, '_'),
                          name: item?.loadName,
                          type: 'load',
                          groupPath: [...parentNode.groupPath],
                          icon: {
                              web: {
                                  family: 'loads',
                                  loadNumber: item?.loadNumber,
                                  sx: {
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      backgroundColor: '#727E84',
                                      color: '#fff',
                                      width: '18px',
                                      height: '18px',
                                      borderRadius: '4px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                  },
                              },
                          },
                          deviceId: parentNode?.id,
                      }))
                    : [
                          {
                              id: 'uniqueqqrlAOad',
                              name: 'No Controllable Loads',
                              type: 'DEVICE',
                              noIcon: true,
                          },
                      ],
            }));
        } else {
            const { data: childHierarchyData }: any = await getGroupChildren({ parent: parentNode?.id });
            if (childHierarchyData?.success) {
                setHierarchyMappingData((prev: any) => ({
                    ...prev,
                    [parentNode?.id]: [...(prev[parentNode?.id] ?? []), ...(childHierarchyData.data?.records ?? [])],
                }));
            }
        }

        setLoadingHierarchyNode((prevState): any => prevState.filter((loadingNode) => loadingNode !== parentNode?.id));
    };

    const handleCleanHierarchyNode = (parentId: string): void => {
        setHierarchyMappingData((prevState: any) => {
            const newHierarchyMappingData: any = { ...prevState };
            delete newHierarchyMappingData[parentId];
            return newHierarchyMappingData;
        });
    };

    const hasChildNode = (treeItem: any): boolean =>
        treeItem?.groupCount ||
        treeItem?.deviceCount ||
        (hierarchyMappingData[treeItem?.id] && hierarchyMappingData[treeItem?.id]?.length) ||
        (treeItem?.deviceTypeId && getWidgetTypeValidationConfig(widgetType)['isSourceRequired'] && !selectedSource);

    return {
        loadingHierarchyNode,
        handleFetchHierarchy: widgetType === 'loads' ? handleFetchLoadsHierarchy : handleFetchHierarchy,
        hasChildNode,
        hierarchyMappingData,
        handleCleanHierarchyNode,
        groupsData,
    };
};
