import React from 'react';

import CustomIcon from '../../../../components/CustomIcon';
import { VIEW_ALL_FOOTER, WidgetFooter, WidgetHeader } from 'pages/WidgetManagement/common';
import { useSortedData } from '../../../../hooks/useSortedData';
import { CloudOffFilled, Device } from '@brightlayer-ui/icons-mui';
import { ChannelValue, InfoListItem } from '@brightlayer-ui/react-components';
import HelpCenter from '@mui/icons-material/HelpCenter';
import Info from '@mui/icons-material/Info';
import NotificationsActive from '@mui/icons-material/NotificationsActive';
import Warning from '@mui/icons-material/Warning';
import { Avatar, Box, Divider, List, Paper, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { useNavigate } from 'react-router-dom';
import { CustomTransComponent, GroupPath } from 'components';
import { CustomEmptyState } from 'components/CustomEmptyState';

const Component = (props: any): JSX.Element => {
    const theme: any = useTheme();
    const navigate = useNavigate();
    const { sortedData } = useSortedData(props?.widgetData ?? {}, ['secondary']);
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);

    const getIcon = (status: string): JSX.Element => {
        switch (status) {
            case 'alarmCount':
                return <NotificationsActive color="error" fontSize={'inherit'} />;
            case 'offlineCount':
                return <CloudOffFilled className={'sky-800'} fontSize={'inherit'} />;
            case 'warningCount':
                return <Warning color="warning" fontSize={'inherit'} />;
            case 'informationalCount':
                return <Info sx={{ color: theme?.palette?.primary?.main }} fontSize={'inherit'} />;
            default:
                return <></>;
        }
    };

    return (
        <Paper sx={{ height: '100%' }}>
            <WidgetHeader
                mode={props?.mode}
                sortedData={sortedData}
                widgetData={props?.widgetData}
                count={
                    props?.widgetData?.multiDevice
                        ? props?.widgetData?.filters?.deviceIds?.length
                        : props?.widgetData?.total
                }
            />
            {props?.mode !== 'edit' && <Divider />}
            <Stack
                sx={{
                    height: `calc(100% - ${
                        props?.mode === 'edit' || props?.widgetData?.viewAllButton ? '97px' : '49px'
                    })`,
                    overflow: 'auto',
                }}
            >
                {sortedData?.secondary?.length ? (
                    sortedData?.secondary?.slice(0, props?.widgetData?.maxCount ?? sortedData?.secondary?.length).map(
                        (device: any): JSX.Element => (
                            <List key={device?.id} disablePadding>
                                <InfoListItem
                                    title={''}
                                    divider={'full'}
                                    iconAlign="center"
                                    statusColor={device?.status?.indicator}
                                    sx={{ height: 78, '& .MuiListItemButton-root': { height: '100%' } }}
                                    leftComponent={
                                        <Stack className="white-space-pre">
                                            <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#424E54' }}>
                                                {device?.name}
                                            </Typography>
                                            <GroupPath pathName={[...(device?.groupPath ?? [])]} width={'220px'} />

                                            <Stack flexDirection={'row'} alignItems={'center'}>
                                                {device?.configuredChannels?.map((attribute: any, index: number) => (
                                                    <Stack
                                                        key={attribute?.key}
                                                        flexDirection="row"
                                                        justifyContent={'space-between'}
                                                        alignItems={'center'}
                                                    >
                                                        <CustomIcon
                                                            iconName={attribute?.icon?.web?.name ?? ''}
                                                            family={attribute?.icon?.web?.family ?? ''}
                                                            iconProps={{
                                                                className: 'height-18 width-18',
                                                                sx: { color: '#727E84' },
                                                            }}
                                                        />
                                                        <ChannelValue
                                                            mx={1}
                                                            value={attribute?.value}
                                                            unitSpace="hide"
                                                            units={attribute?.unit}
                                                        />
                                                        {index !== device?.configuredChannels?.length - 1 && (
                                                            <Box className="border-radius-50 bg-content height-4 width-4 margin-right-8" />
                                                        )}
                                                    </Stack>
                                                ))}
                                            </Stack>
                                        </Stack>
                                    }
                                    icon={
                                        <Avatar sx={{ bgcolor: device?.status?.icon?.web?.style?.backgroundColor }}>
                                            <CustomIcon
                                                iconName={device?.status?.icon?.web?.name ?? ''}
                                                family={device?.status?.icon?.web?.family ?? ''}
                                                iconProps={{
                                                    sx: {
                                                        color: device?.status?.icon?.web?.style?.color,
                                                    },
                                                }}
                                                defaultIcon={<HelpCenter color="primary" />}
                                            />
                                        </Avatar>
                                    }
                                    rightComponent={
                                        <>
                                            {Object.keys(device?.count ?? {}).map((status) =>
                                                device?.count[status] ? (
                                                    <Stack
                                                        key={status}
                                                        flexDirection={'row'}
                                                        alignItems={'center'}
                                                        sx={{ mr: 2 }}
                                                    >
                                                        {getIcon(status)}
                                                        <Typography
                                                            sx={{
                                                                color: device?.indicator,
                                                                fontSize: '14px',
                                                                fontWeight: '600',
                                                                marginLeft: '2px',
                                                            }}
                                                        >
                                                            {device?.count[status]}
                                                        </Typography>
                                                    </Stack>
                                                ) : (
                                                    <></>
                                                )
                                            )}
                                        </>
                                    }
                                    chevron
                                    ripple
                                    onClick={(): void => {
                                        if (props?.mode === 'view')
                                            navigate(`/${currentRealmName}/device/${device?.id}`);
                                    }}
                                />
                            </List>
                        )
                    )
                ) : (
                    <CustomEmptyState
                        icon={<Device fontSize={'inherit'} />}
                        title={<CustomTransComponent translationKey={'COMMON:NO_DEVICES'} />}
                    />
                )}
            </Stack>
            <WidgetFooter
                widgetData={props?.widgetData}
                ids={[]}
                actionBtnName={`View All ${VIEW_ALL_FOOTER['device_list']}`}
                mode={props?.mode}
                disabled={!sortedData?.secondary?.length}
            />
        </Paper>
    );
};

export default Component;
