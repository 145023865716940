import React from 'react';
import { CustomModal, CustomTransComponent } from 'components';
import { resetWidget, setSearchKey } from '@fiji/common/src/features/widgetManagement/widgetSlice';
import { ModalActions } from 'components/CustomModal';
import DoubleArrow from '@mui/icons-material/DoubleArrow';
import { useHandleAddWidgets } from './hooks';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { Grid, Stack, Typography } from '@mui/material';
import { WidgetsColumn, DataSourceModal, WidgetTypesGrid, NewWidgetModal } from './';
import { InputWithCustomPlaceholder } from 'components/InputWithCustomPlaceholder';
import { CustomEmptyState } from 'components/CustomEmptyState';

export const AddWidgetModal = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const modalId = React.useId();

    const newWidgetModalRef = React.useRef<any>(null);
    const dataSourceModalRef = React.useRef<any>(null);

    const payload = useTypedSelector((state) => state.widget?.payload);
    const modalControls = useTypedSelector((state) => state.widget?.addWidgetModal);

    const { handleNextClick } = useHandleAddWidgets();

    const addNewWidgetActions: ModalActions[] = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                dispatch(resetWidget());
            },
        },
        {
            key: 'next',
            label: <CustomTransComponent translationKey={'COMMON:NEXT'} />,
            variant: 'contained',
            disabled: !payload?.widgetType || !payload?.selectedWidget?.id,
            handleClick: (): void =>
                handleNextClick(
                    dataSourceModalRef?.current?.handleModalAction,
                    newWidgetModalRef?.current?.handleModalAction
                ),
        },
    ];

    return (
        <>
            <CustomModal
                key={modalId}
                isOpen={modalControls.isOpen}
                isLoading={false}
                type="secondary"
                actions={addNewWidgetActions}
                header={<CustomTransComponent translationKey={'WIDGETS:ADD_WIDGET'} />}
                paperSx={{ maxHeight: '100%' }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <WidgetTypesGrid />
                    </Grid>
                    <Grid item xs={8}>
                        <Stack
                            className="bg-content-wrapper"
                            sx={{ height: 'calc(100vh - 215px)', overflowY: 'scroll' }}
                            p={3}
                        >
                            <Typography variant="subtitle1" color="primary" fontSize={'16px'} fontWeight={'600'}>
                                <CustomTransComponent translationKey={'WIDGETS:ADD_WIDGET_MODAL.SELECT_WIDGET'} />
                            </Typography>
                            <Stack direction={'row'} my={2}>
                                <InputWithCustomPlaceholder
                                    id="search"
                                    placeholder={'COMMON:SEARCH_PLACEHOLDER'}
                                    disabled={!payload?.widgetType}
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(e: any): void => {
                                        dispatch(setSearchKey(e.target.value));
                                    }}
                                />
                            </Stack>

                            {!payload?.widgetType && (
                                <Stack
                                    className="h-100 overflow-y-scroll bg-white"
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                >
                                    <CustomEmptyState
                                        title={
                                            (
                                                <CustomTransComponent
                                                    translationKey={'WIDGETS:ADD_WIDGET_MODAL.NO_WIDGET_TYPE'}
                                                />
                                            ) as any
                                        }
                                        icon={
                                            <DoubleArrow
                                                fontSize="large"
                                                className="rotate-180 color-icon-light f-60"
                                            />
                                        }
                                    />
                                </Stack>
                            )}

                            {payload?.widgetType && <WidgetsColumn />}
                        </Stack>
                    </Grid>
                </Grid>
            </CustomModal>

            <NewWidgetModal ref={newWidgetModalRef} />
            <DataSourceModal
                ref={dataSourceModalRef}
                type="add"
                handleConfirm={(): void => newWidgetModalRef?.current?.handleModalAction(true)}
            />
        </>
    );
};
